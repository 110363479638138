<template>
  <div class="">
    <div class="home_top">
      <div class="home_top_text">河边镇“党建统领 基层治理”积分管理平台</div>
      <div class="home_top_time">{{ currentTime }}</div>
      <div v-if="showBool" class="topLeftBtnBox">
        <div class="topLeftBtnItem" v-for="(item,index) in btnArr" :key="index" @click="toBack(item)">{{ item.title }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'topNav',
  props:{
    showBool:{
      default:false,
      type:Boolean,
    },
    btnArr:{
      type:Array,
      default: function() {
        return [];
      },
    },
  },
  data(){
    return {
      currentTime:'',
    }
  },
  created(){
    this.getTime()
  },
  mounted(){
    clearInterval(myTimeDisplay );//销毁之前定时器
    var myTimeDisplay = setInterval(() => {
        this.getTime(); //每秒更新一次时间
    }, 1000);
  },
  methods:{
    getTime(){
      // 创建一个Date对象来获取当前时间
      var currentDate = new Date();

      // 获取当前时间的各个部分
      var year = currentDate.getFullYear(); // 年份
      var month = currentDate.getMonth() + 1; // 月份（注意月份从0开始，所以要加1）
      var day = currentDate.getDate(); // 日期
      var hours = currentDate.getHours(); // 小时
      var minutes = currentDate.getMinutes(); // 分钟
      var seconds = currentDate.getSeconds(); // 秒钟

      // 将时间部分转换为两位数形式（如果小于10，在前面加上0）
      month = month < 10 ? '0' + month : month;
      day = day < 10 ? '0' + day : day;
      hours = hours < 10 ? '0' + hours : hours;
      minutes = minutes < 10 ? '0' + minutes : minutes;
      seconds = seconds < 10 ? '0' + seconds : seconds;

      // 构建时间字符串
      // this.currentTime = year + '年' + month + '月' + day + '日 ' + hours + ':' + minutes + ':' + seconds;
      this.currentTime = month + '月' + day + '日 ';
      let time =  year + '-' + month;
      localStorage.setItem('datatime',time)
    },

    toBack(item){
      this.$router.push(item.routerUrl)
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
&_top{
    background: url(@/assets/pageTopbackImg.png) no-repeat;
    background-size: cover;
    width: 100%;
    padding: 20px 0;
    position: relative;
    &_text{
      background: linear-gradient(rgb(255,255,255), rgb(133,189,255));
      background-clip: text;
      -webkit-background-clip: text;
      color: transparent;
      font-size: 30px;
      font-weight: bold;
      text-align: center;
    }
    &_time{
      position: absolute;
      bottom: 0;
      left: 40px;
      width: 50%;
      color: #FFF;
      font-size: 14px;
    }
  }
.topLeftBtnBox{
  position: absolute;
  bottom: 0;
  right: 40px;
  display: flex;
  align-items: center;
}
.topLeftBtnItem{
  color: #FFF;
  background: url(@/assets/toprightbtn.png) no-repeat;
  background-size: 100% 100%;
  width: 135px;
  height: 35px;
  line-height: 35px;
  text-align: center;
  cursor: pointer;
}
</style>
