<template>
  <div class="home">
    <topnav />
    <div class="home_items">
      <div class="home_items_box" v-for="(item, index) in list" :key="index">
        <div class="home_items_item" @click="skipTo(item)">
          <div>
            <img class="home_items_img" :src="item.imgurl" alt="">
          </div>
          <div class="home_items_item_text">{{ item.title }}</div>
        </div>
      </div>
    </div>
    <div class="home_bottom">
      璧山区河边镇&copy;版权所有 备案编号:渝12445511415548
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import topnav from '@/components/topNav.vue'
export default {
  name: 'HomeView',
  components: {
    topnav,
  },
  data() {
    return {
      list: [
        { title: '积分查询', id: 1, imgurl: require('@/assets/home/bg1.png'), kipRouter: '/search' },
        { title: '河边镇综合数据', id: 2, imgurl: require('@/assets/home/bg2.png'), kipRouter: '/street' },
        { title: '村社积分综合数据', id: 3, imgurl: require('@/assets/home/bg3.png'), kipRouter: '/community' },
        // {title:'红岩红暖心数据',id:4,imgurl:require('@/assets/home/bg4.png'),kipRouter:'/redrock'},
        // {title:'网格员数据',id:4,imgurl:require('@/assets/home/bg5.png'),kipRouter:'/gridMan'},
      ],
    }
  },
  created() {
  },
  methods: {
    skipTo(item) {
      this.$router.push(item.kipRouter)
    },
  }
}
</script>
<style>
.home {
  background: url(@/assets/backImg.jpg) no-repeat;
  background-size: 100% 100%;
  width: 100%;
  height: 100vh;
  position: relative;
}

.home_bottom {
  position: absolute;
  bottom: 50px;
  left: 0;
  width: 100%;
  text-align: center;
  color: #ffffff;
}

.home_items {
  color: #FFF;
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 80%;
  margin: 0 auto;
  margin-top: 260px;
}

.home_items_box {
  background: url(@/assets/home/itemBackImg.png) no-repeat;
  background-size: 100% 100%;
  width: 260px;
  height: 224px;
  text-align: center;
}

.home_items_img {
  width: 104px;
  height: 104px;
}

.home_items_item {
  padding: 35px 0;
}

.home_items_item_text {
  background: linear-gradient(rgb(99, 177, 238), rgb(62, 138, 237));
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  font-size: 22px;
  font-weight: bold;
  text-align: center;
  margin-top: 15px;
}

.home_top {
  background: url(@/assets/pageTopbackImg.png) no-repeat;
  background-size: cover;
  width: 100%;
  padding: 20px 0;
  position: relative;
}

.home_top_text {
  background: linear-gradient(rgb(255, 255, 255), rgb(133, 189, 255));
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  font-size: 30px;
  font-weight: bold;
  text-align: center;
}

.home_top_time {
  position: absolute;
  bottom: 0;
  left: 40px;
  width: 50%;
  color: #FFF;
  font-size: 14px;
}</style>
